import React from 'react';
import Navbar from '../components/nav.jsx'
import Footer from '../components/footer.jsx'

function Book() {
    return (
        <div className='container'>
            <Navbar></Navbar>
            <h2>BOOKS</h2>
            <div className="books">
                <h3>Grimm's Future</h3>
                <p>Discover a captivating collection of futuristic tales that will transport you to a world where technology reigns and the boundaries of imagination are pushed to their limits. Welcome to my latest book, a compilation of short stories inspired by Grimms fairytales with a modern twist. Each narrative explores the intersection of future and technology, inviting readers to ponder the possibilities and consequences of our rapidly advancing world.
                <br />
                <br />
                Co-written by ChatGPT, an advanced language model developed by OpenAI, and enhanced with visually stunning images generated by Midjourney, this book is a unique collaboration between human creativity and artificial intelligence. The result is a seamless blend of imaginative storytelling and cutting-edge technology, offering readers an unforgettable reading experience.
                <br />
                <br />
                Within these pages, you'll encounter a diverse range of narratives that delve into the complexities of our future society. Embark on an odyssey-like journey alongside a man desperate to navigate the bureaucratic challenges of a futuristic DMV, where even the simplest task becomes an epic adventure. Or delve into the depths of a world pact suicide, where all but one man decides to end their lives, raising thought-provoking questions about the value of human existence in an interconnected world.
                <br />
                <br />
                This book aims to entertain, challenge, and ignite your imagination, pushing the boundaries of what is possible in the realm of speculative fiction. By seamlessly blending classic fairy tale elements with futuristic themes, it offers a fresh perspective on timeless storytelling, highlighting the societal impact of technology in our lives.
                <br />
                <br />
                Allow yourself to be immersed in these compelling stories, where humanity's relationship with technology takes center stage. Each tale is a unique exploration of the human condition, prompting readers to reflect on the consequences of our choices and the potential futures that lie ahead.
                <br />
                <br />
                To get a taste of what awaits you, feel free to explore a few sample stories provided below. They offer a glimpse into the breadth and depth of this collection, giving you a taste of the enthralling narratives that await within these pages.
                <br />
                <br />
                Prepare to be captivated by the merging of classic storytelling and futuristic themes. Immerse yourself in a world of possibilities, where imagination meets technology, and discover the boundless creativity that emerges when human and artificial intelligence collaborate.</p>
                <div className="samples">
                    <div className="media">
                        <a href="/the-last-man">
                            <img src="https://cdn.midjourney.com/50b2c7da-8c9c-490f-b32c-e0dd24a25b06/0_0.png" alt="The Last Man Cover Art" />
                            <h4>The Last Man</h4>
                            <p>In a world embracing a suicide pact, John's refusal leaves him alone to rediscover the beauty of life.</p>
                        </a>
                    </div>
                    <div className="media">
                        <a href="/dmv-2099">
                            <img src="https://cdn.midjourney.com/878d5bff-33ca-4841-a6b4-73b69b5095ce/grid_0.png" alt="DMV2099 Cover Art" />
                            <h4>DMV2099</h4>
                            <p>"DMV 2099" is a gripping tale of a man's arduous journey through a futuristic, isolated DMV building, testing his resilience and desire for human connection.</p>
                        </a>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Book;